.darken input{
    background-color: #383c44 !important;
    border-color: #383c44 !important;
}

.darken label, .darken input, .darken input:focus{
    color: #A8AcB4;
}

p > span{
    font-family: monospace;
}

.health > div{
    overflow-y: visible;
}

.multiOptionSelect input{
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.multiselect-container .searchWrapper {
    border-radius: 10px;
    background-color: white;
}

.multiOptionSelect{
    min-height: 35vh;
}

.chip{
    background-color: var(--goopter-green) !important;
}

.multiOptionSelect li:hover,
.multiOptionSelect li.highlight{
    background-color: var(--goopter-green) !important;
}
