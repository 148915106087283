:root {
  --map-width: 70vw;
}

.home {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.home header h1 {
  font-size: 3rem;
  color: var(--light);
  margin-bottom: 2.5rem;
}

.home header > h1:first-of-type {
  color: var(--goopter-green);
  margin-right: 1rem;
}

.home header > h1:last-of-type,
.home a {
  font-weight: 350;
}

.home header > h1:first-of-type::first-letter {
  color: var(--goopter-red);
}

.home nav div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home > div:last-child {
  margin: 45px;
  width: var(--map-width);
  height: 28.125vw;
  overflow: hidden;
  position: relative;
}
.home > div:last-child > :first-child {
  z-index: 1;
}

.home nav a {
  margin: 10px;
  color: var(--goopter-green);
  font-size: 1.5rem;
}

.home nav a:visited {
  color: var(--goopter-red);
}

.home #overlay-text {
  position: absolute;
  bottom: 5%;
  width: var(--map-width);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home #overlay-text > div {
  background-color: var(--light);
  border-top: 2px solid var(--goopter-red);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  border-radius: 4px;
  box-shadow: 0px 7px 15px -3px rgba(0, 0, 0, 0.66) !important;
}

.home #overlay-text > div > h6 {
  margin: 0 !important;
  line-height: 2.5;
  font-weight: 400;
}

@media only screen and (max-width: 1024px) {
  .home header {
    flex-direction: column;
  }
  :root {
    --map-width: 90vw;
  }
  .home > div:last-child {
    height: 85vh;
  }
  header {
    max-height: 230px;
    height: 37vw;
  }
  nav {
    max-height: 230px;
    height: 19vh;
    max-width: 800px;
    width: 70vw;
  }
  .home nav a {
    font-size: 1.3rem;
  }
  .home #overlay-text > div > h6 {
    line-height: 1.5;
    font-size: 1rem;
  }
  .home header h1:first-child {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .home header h1:last-child {
    margin-bottom: 1rem;
  }
  nav div {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 768px) {
  nav {
    height: 32vh;
  }
}

@media only screen and (max-width: 599px) {
  .home header h1 {
    font-size: 2rem;
  }
  .home nav a {
    font-size: 0.9rem;
  }
  .home #overlay-text > div > h6 {
    line-height: 1.8;
    font-size: 0.8rem;
  }

  header {
    height: 55vh;
  }

  nav {
    height: 40vh;
    max-width: 600px;
    width: 96vw;
  }
}

@media only screen and (max-width: 414px) {
  header {
    height: 37vh;
  }
  nav {
    height: 52vh;
  }
}

@media only screen and (max-width: 375px) {
  nav {
    height: 35vh;
  }
  header {
    height: 33vh;
  }
  .home nav a {
    font-size: 0.7rem;
  }
  .home header h1 {
    font-size: 1.7rem;
  }
}

@media only screen and (max-width: 320px) {
  nav {
    height: 55vh;
  }
  header {
    height: 40vh;
  }
}

@media only screen and (max-width: 280px) {
  nav {
    height: 80vh;
  }
  header {
    height: 40vh;
  }
  nav {
    width: 95vw;
  }
}
