.map-store-canvas {
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

.address-display {
  position: absolute;
  z-index: 2;
  right: 2.5vh;
  top: 2.5vh;
  font-size: 1.75rem;
  padding: 8px;
  background-color: #ffffff90;
  border: 2px solid var(--light);
  border-radius: 10px;
}

@media only screen and (max-width: 599px) {
  .address-display {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 414px) {
  .address-display {
    font-size: 0.7rem;
    right: auto;
  }
}
