.map-store-canvas {
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

.address-search {
  position: absolute;
  width: 50vw;
  left: 25vw;
  right: 25vw;
  top: 5vh;
  z-index: 2;
  overflow-y: visible;
}

.directions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  color: black;
  width: 20vw;
  height: 100vh;
}
.store-container {
  display: flex;
  flex-direction: row-reverse;
  height: 100vh;
}

.directions-label {
  font-size: 1.8vh;
  margin: 4vh 0 1vh 0;
}

.directions-city-region {
  font-size: 1.5vh;
  margin: 0 0 2vh 0;
}

@media only screen and (min-device-width: 280px) and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
  /* For mobile phones: */
  .address-search {
    position: absolute;
    width: 95vw;
    left: 2.5vw;
    top: 3vh;
  }

  .map-store-canvas {
    height: 800vh;
    width: 100vw;
    z-index: 1;
  }

  .store-container {
    display: flex;
    flex-direction: column;
  }

  .directions-container {
    display: flex;
    flex-direction: column;
    align-items: initial;
    background-color: white;
    color: black;
    width: 100vw;
    height: 24vh;
  }

  .directions-label {
    margin: 2vh 0 0 4vw;
    font-size: 3vh;
  }

  .directions-city-region {
    margin-left: 4vw;
    font-size: 2vh;
  }
  
  .directions-button {
    margin: 0 0 0 4vw;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
  /* For tablets: */
  .address-search {
    position: absolute;
    width: 95vw;
    left: 2.5vw;
    top: 3vh;
  }
  .store-container {
    display: flex;
    flex-direction: column;
  }
  .directions-container {
    display: flex;
    flex-direction: column;
    align-items: initial;
    background-color: white;
    color: black;
    width: 100vw;
    height: 25vh;
  }
  .directions-label {
    margin: 3vh 0 0 4vw;
    font-size: 3vh;
  }
  .directions-city-region {
    margin-left: 4vw;
    font-size: 2vh;
  }
  .directions-button {
    margin: 0 0 0 4vw;
  }
  .btn-tablet {
    width: 18vw;
    height: 5vh;
    font-size: 2vh;
  }
}
