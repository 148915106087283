.controls {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.map-canvas {
  height: 80vh;
}

.map .floating-label input {
  background-color: var(--light) !important;
}

