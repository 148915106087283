:root {
  --goopter-green: #18b7b5;
  --goopter-red: #ef3e24;
  --goopter-blue: #55a89a;
  --goopter-blue-faded: #55a89aa0;
  --dark: #282c34;
  --light: #edebe9;
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: var(--dark);
}

p,
p * {
  color: var(--light);
  text-align: justify;
  font-size: 1.15rem;
  font-weight: 350;
  line-height: 1.5;
}

hr {
  width: 100%;
  opacity: 1 !important;
  color: var(--goopter-green);
}

body > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.section-header::first-letter {
  color: var(--goopter-red);
}

.section-header {
  color: var(--goopter-green);
  font-size: 2.5rem;
  font-weight: 350;
  text-align: center;
}

pre {
  overflow-x: hidden !important;
}

.health {
  flex-wrap: nowrap;
}

@media only screen and (min-device-width: 280px) and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
  /* For mobile phones: */
  .health {
    flex-wrap: wrap;
  }
}
