.map-store-canvas {
    height: 100vh;
    width: 100vw;
    z-index: 1;
}

.directions-search {
    position: absolute;
    left: 0;
    top: 0;
    width: 25vw;
    height: 100vh;
    z-index: 2;
    overflow-x: visible !important;
}

@media only screen and (min-device-width: 280px) and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
    /* For mobile phones: */
    .directions-search {
        width: 85vw;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
    /* For tablets: */
    .directions-search {
        width: 35vw;
    }
}